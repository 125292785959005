import React, { useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'

const HelpCta = loadable(() => import('/src/components/Help/HelpCta'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const Search = loadable(() => import('/src/components/Search/Search'))
const SearchResultsContainer = loadable(() => import('/src/components/Search/SearchResults/SearchResultsContainer'))
const SearchResultsPageList = loadable(() => import('/src/components/Search/SearchResults/SearchResultsPageList'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))
const Text = loadable(() => import('/src/components/PageBuilder/layouts/standard/Text'))

const HelpTemplate = ({ data: { search, post } }) => {
    const [options] = useContext(OptionsContext)

    const postTypeInfo = {
        postType: options.helpCentre.postTypeName,
        title: options.helpCentre.title,
        uri: options.helpCentre.relativeUrl,
        hero: {
            eyebrow: options.helpCentre.heroEyebrow,
            title: options.helpCentre.heroTitle
        }
    }

    const [categoryName, setCategoryName] = useState('')
    const [categoryLink, setCategoryLink] = useState('')
    const [currentCategoryPosts, setCurrentCategoryPosts] = useState('')

    useEffect(() => {
        post.helpCategories.nodes.forEach((node) => {
            setCategoryName(node.name)
            setCategoryLink(node.link)

            setCurrentCategoryPosts(node.helpCentre.nodes.filter((node) => node.id !== post.id)) // Remove post from the array if it is the current one
        })
    }, [post])

    const breadcrumb = {
        type: 'standard',
        pageTitle: post.title,
        pageParent: {
            node: {
                title: categoryName,
                uri: categoryLink,
                wpParent: {
                    node: {
                        title: postTypeInfo.title,
                        uri: postTypeInfo.uri
                    }
                }
            }
        }
    }

    return (
        <LayoutInner>
            <Seo post={post} />

            <main>
                <article itemScope itemType="http://schema.org/Article">
                    <Search
                        postTypeInfo={postTypeInfo}
                        breadcrumb={breadcrumb}
                        headingLevel={'span'}
                        searchData={search}
                    />

                    <Text
                        titleArea={{
                            titlePosition: 'inline',
                            addTitleArea: true,
                            title: post.title
                        }}
                        headingLevel={'h1'}
                        content={post.content}
                        addSmallprint={true}
                        smallprint={options.helpCentre.articleDisclaimer}
                    />

                    {currentCategoryPosts.length > 0 && (
                        <SearchResultsContainer
                            size={'narrow'}
                            title={`Related articles from <span>${categoryName}</span>:`}
                        >
                            <SearchResultsPageList itemsRepeater={currentCategoryPosts} />
                        </SearchResultsContainer>
                    )}

                    <HelpCta backgroundColour={'alt'} />
                </article>
            </main>
        </LayoutInner>
    )
}

HelpTemplate.propTypes = {
    /**
     * Post object
     */
    data: PropTypes.object.isRequired
}

export default HelpTemplate

export const pageQuery = graphql`
    query HelpById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
    ) {
        search: localSearchPages {
            index
            store
        }
        # selecting the current post by id
        post: wpHelp(id: { eq: $id }) {
            id
            slug
            title
            content
            helpCategories {
                nodes {
                    name
                    link
                    helpCentre {
                        nodes {
                            id
                            title
                            uri
                        }
                    }
                }
            }

            ...SeoForHelp
        }
    }
`
